//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Time',
  props: {
    highlightPositive: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      required: true
    }
  }
};