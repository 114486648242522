import * as fns from "date-fns";
import { format } from "date-fns";
export function isSpecialUser(uid) {
  return uid === 'root' || uid === 'darius.hannemann@vh-solutions.de' || uid === 'sebastian.valentiner@vh-solutions.de' || uid === 'elias.huehne@vh-solutions.de';
}
export function today(name) {
  this.view = 'month';
  this.$router.push({
    name: name,
    params: {
      username: this.username || '',
      start: format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'),
      end: format(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'yyyy-MM-dd')
    }
  });
}
export function previousDay(name) {
  this.$router.push({
    name: name,
    params: {
      username: this.username || '',
      start: format(new Date(this.getStartDate.getFullYear(), this.getStartDate.getMonth(), this.getStartDate.getDate() - 1), 'yyyy-MM-dd'),
      end: format(new Date(this.getEndDate.getFullYear(), this.getEndDate.getMonth(), this.getEndDate.getDate() - 1), 'yyyy-MM-dd')
    }
  });
}
export function nextDay(name) {
  this.$router.push({
    name: name,
    params: {
      username: this.username || '',
      start: format(new Date(this.getStartDate.getFullYear(), this.getStartDate.getMonth(), this.getStartDate.getDate() + 1), 'yyyy-MM-dd'),
      end: format(new Date(this.getEndDate.getFullYear(), this.getEndDate.getMonth(), this.getEndDate.getDate() + 1), 'yyyy-MM-dd')
    }
  });
}
export function previousMonth(name) {
  this.$router.push({
    name: name,
    params: {
      username: this.username || '',
      start: format(new Date(this.getStartDate.getFullYear(), this.getStartDate.getMonth() - 1, 1), 'yyyy-MM-dd'),
      end: format(new Date(this.getEndDate.getFullYear(), this.getEndDate.getMonth(), 0), 'yyyy-MM-dd')
    }
  });
}
export function nextMonth(name) {
  this.$router.push({
    name: name,
    params: {
      username: this.username || '',
      start: format(new Date(this.getStartDate.getFullYear(), this.getStartDate.getMonth() + 1, 1), 'yyyy-MM-dd'),
      end: format(new Date(this.getEndDate.getFullYear(), this.getEndDate.getMonth() + 2, 0), 'yyyy-MM-dd')
    }
  });
}
export function nextYear(name) {
  this.$router.push({
    name: name,
    params: {
      username: this.username || '',
      start: format(new Date(this.getStartDate.getFullYear() + 1, this.getStartDate.getMonth(), 1), 'yyyy-MM-dd'),
      end: format(new Date(this.getEndDate.getFullYear() + 1, this.getEndDate.getMonth() + 1, 0), 'yyyy-MM-dd')
    }
  });
}
export function previousYear(name) {
  this.$router.push({
    name: name,
    params: {
      username: this.username || '',
      start: format(new Date(this.getStartDate.getFullYear() - 1, this.getStartDate.getMonth(), 1), 'yyyy-MM-dd'),
      end: format(new Date(this.getEndDate.getFullYear() - 1, this.getEndDate.getMonth() + 1, 0), 'yyyy-MM-dd')
    }
  });
}
export function thisMonth(name) {
  this.$router.push({
    name: name,
    params: {
      username: this.username || '',
      start: format(new Date(this.getStartDate.getFullYear() - 1, this.getStartDate.getMonth(), 1), 'yyyy-MM-dd'),
      end: format(new Date(this.getEndDate.getFullYear() - 1, this.getEndDate.getMonth() + 1, 0), 'yyyy-MM-dd')
    }
  });
}
export function getCurrentMonthAndYear() {
  if (this.getStartDate.getMonth() === this.getEndDate.getMonth()) {
    return this.getStartDate.toLocaleString('default', {
      month: 'long'
    }) + ' ' + this.getStartDate.getFullYear();
  } else {
    return this.getStartDate.toLocaleString('default', {
      month: 'long'
    }) + ' - ' + this.getEndDate.getMonth().toLocaleString('default', {
      month: 'long'
    }) + ' ' + this.getStartDate.getFullYear();
  }
}
export function getCurrentDay() {
  if (this.getStartDate.getDate() === this.getEndDate.getDate()) {
    return this.getStartDate.toLocaleString('default', {
      day: 'numeric'
    }) + '. ' + this.getStartDate.toLocaleString('default', {
      month: 'long'
    }) + ' ' + this.getStartDate.getFullYear();
  } else {
    return this.getStartDate.toLocaleString('default', {
      day: 'numeric'
    }) + '. ' + this.getStartDate.toLocaleString('default', {
      month: 'long'
    }) + ' - ' + this.getEndDate.toLocaleString('default', {
      day: 'numeric'
    }) + '. ' + this.getEndDate.toLocaleString('default', {
      month: 'long'
    }) + ' ' + this.getStartDate.getFullYear();
  }
}
export function toHoursAndMinutes(totalMinutes) {
  var hours = Math.floor(totalMinutes / 60);
  var minutes = totalMinutes % 60;
  return {
    hours: hours,
    minutes: minutes
  };
}
export function toHoursAndMinutesFormatted(totalMinutes) {
  var isNeg = totalMinutes < 0;
  totalMinutes = Math.abs(totalMinutes);

  var _toHoursAndMinutes = toHoursAndMinutes(totalMinutes),
      hours = _toHoursAndMinutes.hours,
      minutes = _toHoursAndMinutes.minutes;

  if (isNaN(hours) || isNaN(minutes)) {
    return 'keine Daten';
  } else {
    if (hours < 10 && hours >= 0) {
      hours = '0' + hours;
    }

    if (minutes < 10 && minutes >= 0) {
      minutes = '0' + minutes;
    }

    if (isNeg) {
      return "-".concat(hours, ":").concat(minutes);
    } else {
      return "".concat(hours, ":").concat(minutes);
    }
  }
}
export function toHoursAndMinutesAndSecondsFormatted(totalSeconds) {
  //Return totalSeconds in a string formatted as HH:MM:SS
  var isNeg = totalSeconds < 0;
  totalSeconds = Math.abs(totalSeconds);
  var hours = Math.floor(totalSeconds / 3600);
  var minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  var seconds = totalSeconds - hours * 3600 - minutes * 60;

  if (hours < 10 && hours >= 0) {
    hours = '0' + hours;
  }

  if (minutes < 10 && minutes >= 0) {
    minutes = '0' + minutes;
  }

  if (seconds < 10 && seconds >= 0) {
    seconds = '0' + seconds;
  }

  if (isNeg) {
    return "-".concat(hours, ":").concat(minutes, ":").concat(seconds);
  }

  return "".concat(hours, ":").concat(minutes, ":").concat(seconds);
}
export function getDateGermanFormatted(date) {
  return fns.format(date, 'dd.MM.yyyy');
}