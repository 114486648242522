//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import NcLoadingIcon from '@nextcloud/vue/dist/Components/NcLoadingIcon.js';
import useSWRV from "swrv";
import { generateUrl } from "@nextcloud/router";
import axios from "@nextcloud/axios";
import { getCurrentUser } from "@nextcloud/auth";
import { showError, showSuccess } from "@nextcloud/dialogs";
import NcButton from "@nextcloud/vue/dist/Components/NcButton";
import Time from "../Time.vue";
import { toHoursAndMinutesAndSecondsFormatted, toHoursAndMinutesFormatted } from "../util";
import { differenceInMinutes, differenceInSeconds, endOfDay, format, isAfter } from "date-fns";
export default {
  name: 'Shortcut',
  methods: {
    differenceInSeconds: differenceInSeconds,
    differenceInMinutes: differenceInMinutes,
    format: format,
    toHoursAndMinutesFormatted: toHoursAndMinutesFormatted,
    toHoursAndMinutesAndSecondsFormatted: toHoursAndMinutesAndSecondsFormatted,
    showError: showError,
    start: function start() {
      var _this = this;

      this.submitting = true;
      axios.post(generateUrl('/apps/zeiterfassung/api/work-period/start')).then(function (response) {
        _this.mutateWorkPeriod();

        _this.submitting = false;
      }).catch(function (error) {
        showError(JSON.stringify(error));
        _this.submitting = false;
      });
    },
    end: function end() {
      var _this2 = this;

      this.submitting = true;
      axios.post(generateUrl('/apps/zeiterfassung/api/work-period/end')).then(function (response) {
        if (response.data.error === null) {
          _this2.mutateWorkPeriod();

          showSuccess("Arbeitszeit gespeichert");
        } else {
          showError(response.data.error);
        }

        _this2.submitting = false;
      }).catch(function (error) {
        showError(JSON.stringify(error));
        _this2.submitting = false;
      });
    },
    getNow: function getNow() {
      if (this.workPeriod && !this.workPeriodError) {
        if (this.workPeriod.data.error === null && this.workPeriod.data.data != null) {
          var endOfStartDay = endOfDay(new Date(this.workPeriod.data.data.start));

          if (isAfter(new Date(), endOfStartDay)) {
            this.now = endOfStartDay;
          } else {
            this.now = new Date();
          }
        } else {
          this.now = new Date();
        }
      } else {
        this.now = new Date();
      }
    }
  },
  components: {
    Time: Time,
    NcButton: NcButton,
    NcLoadingIcon: NcLoadingIcon
  },
  setup: function setup() {
    var firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    var lastDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

    var _useSWRV = useSWRV(function () {
      return generateUrl('/apps/zeiterfassung/api/timesheets?username=' + getCurrentUser().uid + "&start=" + format(firstDay, 'yyyy-MM-dd') + "&end=" + format(lastDay, 'yyyy-MM-dd'));
    }, axios.get, {
      revalidateOnFocus: false
    }),
        timesheets = _useSWRV.data,
        timesheetsError = _useSWRV.error,
        mutateTimesheets = _useSWRV.mutate;

    var _useSWRV2 = useSWRV(function () {
      return generateUrl('/apps/zeiterfassung/api/work-period/current');
    }, axios.get, {
      revalidateOnFocus: false
    }),
        workPeriod = _useSWRV2.data,
        workPeriodError = _useSWRV2.error,
        mutateWorkPeriod = _useSWRV2.mutate;

    return {
      timesheets: timesheets,
      timesheetsError: timesheetsError,
      mutateTimesheets: mutateTimesheets,
      workPeriod: workPeriod,
      workPeriodError: workPeriodError,
      mutateWorkPeriod: mutateWorkPeriod
    };
  },
  created: function created() {
    setInterval(this.getNow, 1000);
  },
  computed: {
    getUserTimesheet: function getUserTimesheet() {
      if (this.timesheets && !this.timesheetsError) {
        return this.timesheets.data.data.filter(function (timesheet) {
          return timesheet.username === getCurrentUser().uid;
        })[0];
      } else {
        return null;
      }
    },
    getDayTimesheet: function getDayTimesheet() {
      if (this.timesheets && !this.timesheetsError) {
        return this.getUserTimesheet.entries.filter(function (entries) {
          return entries.day === format(new Date(), 'yyyy-MM-dd') + ' 00:00:00';
        })[0];
      } else {
        return null;
      }
    }
  },
  data: function data() {
    return {
      showModal: false,
      submitting: false,
      now: new Date()
    };
  }
};