var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-menu" },
    [
      _vm.timesheetsError || _vm.workPeriodError ? void 0 : _vm._e(),
      _vm._v(" "),
      !_vm.timesheets || !_vm.workPeriod
        ? void 0
        : _vm.getUserTimesheet.usesZeiterfassung === true
        ? [
            _c(
              "a",
              {
                staticClass: "header-menu",
                attrs: { id: "timeicon", href: "#" },
                on: {
                  click: function ($event) {
                    _vm.showModal = !_vm.showModal
                  },
                },
              },
              [
                _c("div", { staticClass: "timeicon" }, [
                  _c("div", { staticClass: "timeicon-inner" }, [
                    _c(
                      "svg",
                      {
                        staticClass: "svg-inline--fa fa-user-clock fa-w-20",
                        attrs: {
                          "aria-hidden": "true",
                          focusable: "false",
                          "data-prefix": "fas",
                          "data-icon": "user-clock",
                          role: "img",
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 640 512",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            d: "M496 224c-79.6 0-144 64.4-144 144s64.4 144 144 144 144-64.4 144-144-64.4-144-144-144zm64 150.3c0 5.3-4.4 9.7-9.7 9.7h-60.6c-5.3 0-9.7-4.4-9.7-9.7v-76.6c0-5.3 4.4-9.7 9.7-9.7h12.6c5.3 0 9.7 4.4 9.7 9.7V352h38.3c5.3 0 9.7 4.4 9.7 9.7v12.6zM320 368c0-27.8 6.7-54.1 18.2-77.5-8-1.5-16.2-2.5-24.6-2.5h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h347.1c-45.3-31.9-75.1-84.5-75.1-144zm-96-112c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128z",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.showModal
              ? _c("div", { staticClass: "timeicon-modal " }, [
                  _vm.workPeriodError
                    ? _c("div", [_vm._v("failed to load")])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.workPeriod
                    ? _c("div", [_vm._v("loading...")])
                    : _c(
                        "div",
                        [
                          _c("hr", { staticStyle: { "margin-bottom": "4px" } }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "timeicon-modal-content" },
                            [
                              _vm.workPeriod.data.error ===
                              "Keine aktive Arbeitsperiode gefunden"
                                ? [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "NcButton",
                                          {
                                            attrs: {
                                              disabled: _vm.submitting,
                                              type: "secondary",
                                            },
                                            on: { click: _vm.start },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\tZeiterfassung starten\n\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm.workPeriod.data.error === null &&
                                  _vm.workPeriod.data.data.id !== null
                                ? [
                                    _c("div", [
                                      _c(
                                        "h2",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                            "margin-top": "4px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\tDauer:\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.toHoursAndMinutesAndSecondsFormatted(
                                                  _vm.differenceInSeconds(
                                                    _vm.now,
                                                    new Date(
                                                      _vm.workPeriod.data.data.start
                                                    )
                                                  )
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { "margin-top": "2px" } },
                                      [
                                        _c(
                                          "NcButton",
                                          {
                                            attrs: {
                                              disabled: _vm.submitting,
                                              type: "secondary",
                                            },
                                            on: { click: _vm.end },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\tZeiterfassung stoppen\n\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("hr"),
                          _vm._v(" "),
                          _vm.workPeriod.data.error === null &&
                          _vm.workPeriod.data.data.id !== null &&
                          _vm.getDayTimesheet != null &&
                          _vm.getDayTimesheet.start
                            ? _c("div", {
                                staticStyle: { "margin-top": "2px" },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.workPeriod.data.error === null &&
                          _vm.workPeriod.data.data.id !== null
                            ? [
                                _c("span", [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\tStartzeitpunkt:\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.format(
                                          new Date(
                                            _vm.workPeriod.data.data.start
                                          ),
                                          "HH:mm"
                                        )
                                      ) +
                                      " Uhr\n\t\t\t\t\t\t"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.getDayTimesheet != null &&
                          _vm.getDayTimesheet.start
                            ? [
                                _c("span", [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\tZielarbeitszeit:\n\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.toHoursAndMinutesFormatted(
                                          _vm.getDayTimesheet
                                            .targetMinutesOfWork
                                        )
                                      ) +
                                      " Stunden\n\t\t\t\t\t\t"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v("\n\t\t\t\t\tGleitzeitkonto:\n\t\t\t\t\t\t"),
                            _vm
                              .toHoursAndMinutesFormatted(
                                _vm.getUserTimesheet
                                  .combinedTotalMinutesCalculated
                              )
                              .includes("-")
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "rgb(185 28 28)" } },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.toHoursAndMinutesFormatted(
                                            _vm.getUserTimesheet
                                              .combinedTotalMinutesCalculated
                                          )
                                        ) + " Stunden"
                                      ),
                                    ]),
                                  ]
                                )
                              : _c("span", [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.toHoursAndMinutesFormatted(
                                          _vm.getUserTimesheet
                                            .combinedTotalMinutesCalculated
                                        )
                                      ) + " Stunden"
                                    ),
                                  ]),
                                ]),
                          ]),
                        ],
                        2
                      ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }