var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.text.includes("-")
    ? _c("span", { staticClass: "text-red-700" }, [
        _vm._v("\n\t" + _vm._s(_vm.text) + "\n"),
      ])
    : _vm.highlightPositive && _vm.text !== "00:00"
    ? _c("span", { staticClass: "text-green-700" }, [
        _vm._v("\n\t+" + _vm._s(_vm.text) + "\n"),
      ])
    : _c("span", [_vm._v("\n\t" + _vm._s(_vm.text) + "\n")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }